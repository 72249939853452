:root {
  --color-eggshell: #f5f4f3;
  --color-darkblue: #2e3844;
  --color-red: #ff6666;
  --color-light-blue: #c8e1e1;
  --color-beige: #e6c3b4;
  --color-green: #96aa78;
  --color-white: #ffffff;

  --color-new-gray: #babcbf;

  --color-disabled: rgba(112, 112, 112, 0.3);
  --color-bg: #f5f4f3;
  --color-text: #2e3844;
  --color-black: #2e3844;
  /* --color-blue: rgb(206,224,225);
  --color-red2: rgb(225,196,184); */
  --color-focus: #ff6666;
  --color-gray: rgba(245, 245, 245, 0.6);
  --color-disabled-gray: hsl(0, 0%, 90%);
  --color-disabled-readable: hsl(213, 18%, 55%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --color-bg: #000000;
    --color-text: #FFFFFF;
    --color-focus: #FFFFFF;
    --color-gray: #666666;

  }
} */

.li-nobullets {
  list-style-type: none;
}
.ul-nobullets {
  padding-left: 0;
}
body {
  /* padding: 1rem; */
  background-color: var(--color-bg, #ffffff);
  color: var(--color-text, #000000);
  overscroll-behavior-x: none;
}

.bg-light-blue {
  background: var(--color-light-blue, #c8e1e1);
}

pre {
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono",
    "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  white-space: pre-wrap;
  overflow: auto;
  tab-size: 4;
  color: var(--color-bg, #ffffff);
  background: var(--color-focus, #000000);
}

textarea:focus-visible {
  outline-color: var(--color-focus, #000000);
}

textarea {
  border-radius: 0;
  border-color: var(--color-text, #000000);
  border-width: 1px;
  height: 4rem;
  font-size: 1.2rem;
}

label {
  display: block;
  margin: 0.5rem 0rem 0.25rem 0rem;
}

input.questionnaire__file_input {
  background-color: var(--color-text, #000000);
  border-radius: 2px;
  color: var(--color-bg, #000000);
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
}

input.questionnaire__file_input:focus {
  outline-offset: 4px;
  outline-color: var(--color-focus, #000000);
}

.questionnaire_editor__submit > *,
.cluster > * {
  margin: 0.5rem;
}
.questionnaire_editor__submit,
.cluster {
  margin: 0.5rem -0.5rem;
}

/* button.secondary {
  background-color: var(--color-bg, #000000);
  border-radius: 2px;
  border-style: solid;
  border-color: var(--color-text, #FFFFFF);
  color: var(--color-text, #FFFFFF);
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
}


button.secondary :active {
 background-color: var(--color-text, #000000);
 color: var(--color-bg, #FFFFFF);
} */

th {
  text-align: left;
  font-weight: 300;
}

/***************************************************************
TYPOGRAPHT
These styles are subject to the license agreement(s) provided when you purchased the license(s).

https://store.typenetwork.com/cart/eula/type-network
https://store.typenetwork.com/cart/eula/type-network
https://store.typenetwork.com/cart/eula/type-network
***************************************************************/

h2.subLabel {
  color: var(--color-black, #000000);
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-family: "New Atten Bk";
}

.newattenbook body,
p,
div,
section,
button,
h3 {
  font-family: "New Atten Bk";
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}
.menobannerbold,
.question,
h1 {
  font-family: "New Atten Ex Bd", "Lucida Grande", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

.question {
  font-size: 1.5rem;
}

.newattenextrabold,
h2 {
  font-family: "New Atten Ex Bd", "Lucida Grande", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

h2 {
  margin-top: 2rem;
}

h1 {
  font-size: 1.5rem;
  /* hyphens: auto; */
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.subLabel {
  text-transform: uppercase;
  font-family: "New Atten Bk", "Lucida Grande", sans-serif;
  letter-spacing: 0.15em;
  font-size: 0.75rem;
  margin-top: 0.8rem;
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}
p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

@media (min-height: 350px) {
  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
tr {
  background: var(--color-beige, #e6c3b4);
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2;
}

.form {
  background-color: var(--color-beige, #e6c3b4);
  padding: 1em;
}
.form .input {
  margin-bottom: 1em;
}
.input {
  -webkit-appearance: none;
  padding: 0.5rem;
  color: var(--color-text);
  background-color: var(--color-white);
  width: 100%;
  box-sizing: border-box;
  border: 0.0625rem solid var(--color-light-blue);
  border-radius: 0.5rem;
  background: white;
  /* box-shadow: 0 1px 3px -2px #9098A9;*/
  cursor: pointer;
  font-family: inherit;
  height: 3rem;
  resize: none;
}

.scroll-shadows {
  overflow: auto;

  background:
    /* Shadow Cover TOP */ linear-gradient(#f5f4f3 30%, rgba(255, 255, 255, 0)) center top,
    /* Shadow Cover BOTTOM */ linear-gradient(rgba(255, 255, 255, 0), #f5f4f3 70%) center bottom,
    /* Shadow TOP */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center top,
    /* Shadow BOTTOM */ radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

@media (min-height: 350px) {
  label {
    margin: 2rem 0rem 0.5rem 0rem;
  }
}
